<template>
    <div class="alphabet-tool">

        <h3 class="alphabet-tool__intro">{{ $t('dbyloi_site_alphabettool_header') }}</h3>

        <a id="alphabet-tool" class="anchor" />

        <h2 class="alphabet-tool__header"><span>{{ $t('dbyloi_site_alphabettool_disconnector') }}</span></h2>

        <div class="alphabet-tool__letter-selector">
            <p
            class="alphabet-tool__letter-selector-letter"
            :class="{ 'alphabet-tool__letter-selector-letter--active': letterGroup == 0 }"
            @click="setLetterGroup(0)"
            >A-G</p>
            <p
            class="alphabet-tool__letter-selector-letter"
            :class="{ 'alphabet-tool__letter-selector-letter--active': letterGroup == 1 }"
            @click="setLetterGroup(1)"
            >H-N</p>
            <p
            class="alphabet-tool__letter-selector-letter"
            :class="{ 'alphabet-tool__letter-selector-letter--active': letterGroup == 2 }"
            @click="setLetterGroup(2)"
            >O-U</p>
            <p
            class="alphabet-tool__letter-selector-letter"
            :class="{ 'alphabet-tool__letter-selector-letter--active': letterGroup == 3 }"
            @click="setLetterGroup(3)"
            >V-Z</p>
        </div>

        <div class="alphabet-tool__autoplay-button">
            <CTAButton icon="sound" @click="toggleAutoplay()">{{ autoplayText }}</CTAButton>
        </div>

        <div v-if="!isMobile()" class="alphabet-tool__panel-container">

            <!-- <div class="alphabet-tool__panel" :class="classesForIndex(-3)" @click="setCurrent(23)"></div>
            <div class="alphabet-tool__panel" :class="classesForIndex(-2)" @click="setCurrent(24)"></div>
            <div class="alphabet-tool__panel" :class="classesForIndex(-1)" @click="setCurrent(25)"></div> -->

            <AlphabetPanel
            v-for="(letter, i) in alphabetData" :key="i"
            :letter="letter.letter"
            :ref="`letter${letter.letter}`"
            v-bind="letter"
            :active="curIndex == i"
            :class="classesForIndex(i)"
            @click="setCurrent(i)"
            @prev="setCurrent(i-1)"
            @next="setCurrent(i+1)"
            @playpause="playpause()"
            @share="handleShare()"
            :playing="playing"
            controls
            />

            <!-- <div class="alphabet-tool__panel" :class="classesForIndex(26)" @click="setCurrent(0)"></div>
            <div class="alphabet-tool__panel" :class="classesForIndex(27)" @click="setCurrent(1)"></div>
            <div class="alphabet-tool__panel" :class="classesForIndex(28)" @click="setCurrent(2)"></div> -->

        </div>

        <!-- Letters -->
        <div class="alphabet-tool__letters-container">
            <div class="alphabet-tool__letters-col-wrapper" :class="`alphabet-tool__letters-col-wrapper--${letterGroup}`">
            <template v-for="n in 4" :key="n">
                <div class="alphabet-tool__letters-col" :class="`alphabet-tool__letters-col--${n-1}`">
                    <div v-for="(letter, i) in col(n)" :key="i"
                    class="alphabet-tool__letters-letter"
                    @click="setCurrent((n-1)*7+i)"
                    >
                        <p class="alphabet-tool__letters-title" :class="{'alphabet-tool__letters-title--active': curIndex == (n-1)*7+i}">{{ $t(letter.title) }}</p>
                        <p class="alphabet-tool__letters-short-title" :class="{'alphabet-tool__letters-short-title--active': curIndex == (n-1)*7+i}">{{ $t(letter.shortTitle) }}</p>
                        <span class="alphabet-tool__letters-plus">
                            <img v-svg-inline src="@/assets/app-images/svgs/plus.svg" />
                        </span>
                        <!-- Mobile panel -->
                                    <AlphabetPanel
                                        v-if="isMobile()"
                                        :letter="letter.letter"
                                        :ref="`letter${letter.letter}`"
                                        v-bind="letter"
                                        :active="curIndex == (n-1)*7+i"
                                        class="alphabet-tool__panel-mobile"
                                        :class="classesForIndex(i)"
                                        @playpause="playpause()"
                                        :playing="playing"
                                        />
                    </div>
                </div>
            </template>
            </div>

            <div class="alphabet-tool__letters-reveal">
                <CTAButton whitebordered @click="setLetterGroup(0)" icon="scrub-back" v-show="letterGroup==1">Reveal A-G</CTAButton>
                <CTAButton whitebordered @click="setLetterGroup(1)" icon="scrub-back" v-show="letterGroup==2">Reveal H-N</CTAButton>
                <CTAButton whitebordered @click="setLetterGroup(2)" icon="scrub-back" v-show="letterGroup==3">Reveal O-U</CTAButton>

                <CTAButton whitebordered @click="setLetterGroup(1)" icon="scrub-forward" v-show="letterGroup==0">Reveal H-N</CTAButton>
                <CTAButton whitebordered @click="setLetterGroup(2)" icon="scrub-forward" v-show="letterGroup==1">Reveal O-U</CTAButton>
                <CTAButton whitebordered @click="setLetterGroup(3)" icon="scrub-forward" v-show="letterGroup==2">Reveal V-Z</CTAButton>

            </div>
        </div>

        <AudioPlayer ref="player" :curTrack="curIndex" :autoPlay="autoPlay" @pause="onPause" @play="onPlay" @finish="onFinish" />

        <div class="alphabet-tool__listen-again">
            <h3 class="alphabet-tool__listen-again-header">{{ $t('dbyloi_site_alphabettool_listen_again_header') }}</h3>
            <p class="alphabet-tool__listen-again-body">{{ $t('dbyloi_site_alphabettool_soundcloud_cta_header') }}</p>
            <CTAButton blue icon="sound"><a target="_new" href="https://soundcloud.com/user-201866367/sets/dont-bet-your-life-on-it-alphabet-tool">{{ $t('dbyloi_generic_soundcloud_cta') }}</a></CTAButton>
        </div>

        <p class="alphabet-tool__finally">{{ $t('dbyloi_site_alphabettool_finally_body') }}</p>

    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import AlphabetPanel from './AlphabetPanel.vue';
import AudioPlayer from './AudioPlayer.vue';
import CTAButton from '@/components/common/CTAButton.vue';
import YamlService from '@/service/YamlService';
import AlphabetData from '@/alphabet.json';

interface AlphabetData {
    letter: string;
    title: string;
    shortTitle: string;
    subtitle: string;
    body: string;
}

@Options({
    props: {
    },
    components: {
        AlphabetPanel, AudioPlayer, CTAButton
    }
})
export default class AlphabetTool extends Vue {

    private playing = false;
    private curIndex = 0;
    // eslint-disable-next-line
    private alphabetData = this.getAlphabetData();
    private block = false;
    private autoPlay = false;
    private letterGroup = 0;

    private lefts: Array<number> = [23,24,25];
    private rights: Array<number> = [1,2,3];

    getAlphabetData(): Array<AlphabetData>
    {
        const data: Array<AlphabetData> = YamlService.stripTrans(AlphabetData)['letters'];

        return data;
    }

    private handleShare()
    {
        console.log('Share', this.alphabetData[this.curIndex]);
    }

    setCurrent(i: number)
    {
        if(this.block || i == this.curIndex) return;

        this.curIndex = i;
        if(i < 0) this.curIndex = 26 + i%26;
        if(i > 25) this.curIndex = i%26;

        // Update the lefts and rights arrays
        this.lefts = [this.curIndex-3, this.curIndex-2, this.curIndex-1].map((v) => v < 0 ? 26+v : v);
        this.rights = [this.curIndex+1, this.curIndex+2, this.curIndex+3].map((v) => v > 25 ? v-26 : v);

        // Stop the player
        this.playing = false;

        const player = (this.$refs.player as AudioPlayer);
        if(player) player.pause();

        this.block = true;
        setTimeout(() => this.block = false, 500);
    }

    setLetterGroup(i: number)
    {
        this.letterGroup = i;
        this.setCurrent(i*7);
    }

    classesForIndex(i: number)
    {
        const classes = [];

        // Update card position based on current maps
        if(this.lefts.includes(i))
        {
            const delta = 3 - this.lefts.indexOf(i);
            classes.push(`alphabet-tool__panel--neg${delta}`);
        }
        else if(this.rights.includes(i))
        {
            const delta = 1 + this.rights.indexOf(i);
            classes.push(`alphabet-tool__panel--pos${delta}`);
        }
        else if(i !== this.curIndex){
            classes.push(`alphabet-tool__panel--hidden`);
        }

        /**
         * Below was the mod to allow wrapping without left/right arrays.
         */

        // const diff = Math.min(4, Math.abs(this.curIndex - i));

        // if(i < this.curIndex)
        // {
        //     // classes.push(`alphabet-tool__panel--neg${diff}`);
        //     classes.push(`alphabet-tool__panel--prev`);

        //     if(this.curIndex <= 25 && this.curIndex >= 22 && i <= 3)
        //     {
        //         const wrapDiff = 1 + i;
        //         classes.push(`alphabet-tool__panel--pos${wrapDiff}`);
        //     } else {
        //         classes.push(`alphabet-tool__panel--neg${diff}`);
        //     }
        // }

        // if(i > this.curIndex)
        // {

        //     classes.push(`alphabet-tool__panel--next`);

        //     if(this.curIndex >= 0 && this.curIndex <= 3 && i >= 22)
        //     {
        //         const wrapDiff = Math.min(4, 26 + this.curIndex - i);

        //         classes.push(`alphabet-tool__panel--neg${wrapDiff}`);
        //     } else {
        //         classes.push(`alphabet-tool__panel--pos${diff}`);
        //     }
        // }


        if(i == this.curIndex)
        {
            classes.push(`alphabet-tool__panel--center`);
        }

        return classes;
    }

    col(n: number)
    {
        const i = n-1;
        return this.alphabetData.slice(i*7, i*7+7);
    }

    playpause()
    {
        this.playing = !this.playing;

        if(this.playing)
        {
            (this.$refs.player as AudioPlayer).play();
        } else {
            (this.$refs.player as AudioPlayer).pause();
        }
    }

    onPause()
    {
        this.playing = false;
    }

    onPlay()
    {
        this.playing = true;
    }

    onFinish()
    {
        if(this.autoPlay && this.curIndex < 25)
        {
            this.setCurrent(this.curIndex+1);
            // this.curIndex +=1;
            const newGroup = Math.floor((this.curIndex)/7);
            console.log(`Updating letter group: ${newGroup}`);
            this.letterGroup = newGroup;
        } else {
            this.autoPlay = false;
            this.curIndex
        }
    }

    toggleAutoplay()
    {
        this.autoPlay = !this.autoPlay;

        if(this.autoPlay)
        {
            (this.$refs.player as AudioPlayer).play();
        } else {
            (this.$refs.player as AudioPlayer).pause();
        }
    }

    get autoplayText()
    {
        if(!this.autoPlay) return this.$t('dbyloi_generic_autoplay_all_cta');

        return "Stop Autoplay";
    }

}
</script>