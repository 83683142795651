
<template>
    <div class="socials">
        <a href="https://twitter.com/PGSolutions1" target="_new">
            <svg class="twitter" xmlns="http://www.w3.org/2000/svg" width="39.898" height="32.424" viewBox="0 0 39.898 32.424">
                <path class="main" id="Path_845" data-name="Path 845" d="M89.9,72.571a16.369,16.369,0,0,1-4.7,1.289,8.212,8.212,0,0,0,3.6-4.529,16.381,16.381,0,0,1-5.2,1.986,8.193,8.193,0,0,0-13.948,7.466,23.239,23.239,0,0,1-16.872-8.552A8.194,8.194,0,0,0,55.311,81.16,8.153,8.153,0,0,1,51.6,80.136c0,.034,0,.068,0,.1a8.191,8.191,0,0,0,6.566,8.026,8.206,8.206,0,0,1-3.7.14,8.194,8.194,0,0,0,7.647,5.684,16.422,16.422,0,0,1-10.166,3.5A16.6,16.6,0,0,1,50,97.479a23.168,23.168,0,0,0,12.548,3.678c15.056,0,23.289-12.473,23.289-23.29q0-.532-.024-1.059A16.631,16.631,0,0,0,89.9,72.571Z" transform="translate(-50 -68.733)" opacity="0.262"/>
            </svg>
        </a>

        <a href="https://www.facebook.com/DontBetYourLifeOnIt" target="_new">
            <svg class="facebook" xmlns="http://www.w3.org/2000/svg" width="15.697" height="33.645" viewBox="0 0 15.697 33.645">
                <path class="main" id="Path_844" data-name="Path 844" d="M10.437,33.645H3.479V16.823H0v-5.8H3.479V7.539C3.479,2.815,5.444,0,11.018,0H15.66V5.8h-2.9c-2.169,0-2.316.812-2.316,2.325l-.009,2.9H15.7l-.618,5.8H10.437Z" opacity="0.262"/>
            </svg>
        </a>

        <a href="https://www.youtube.com/channel/UCfT2QXiq_4XWJr_Zqtet5kA" target="_new">
            <svg class="youtube" id="Group_600" data-name="Group 600" xmlns="http://www.w3.org/2000/svg" width="46.553" height="32.587" viewBox="0 0 46.553 32.587">
                <path class="main" id="Path_846" data-name="Path 846" d="M130.13,90.128a5.831,5.831,0,0,0-4.116-4.116c-3.63-.973-18.188-.973-18.188-.973s-14.557,0-18.188.973a5.831,5.831,0,0,0-4.116,4.116c-.973,3.63-.973,11.205-.973,11.205s0,7.575.973,11.205a5.83,5.83,0,0,0,4.116,4.116c3.63.973,18.188.973,18.188.973s14.557,0,18.188-.973a5.83,5.83,0,0,0,4.116-4.116c.973-3.63.973-11.205.973-11.205S131.1,93.758,130.13,90.128Z" transform="translate(-84.55 -85.039)" fill="#b4b7b8"/>
                <path id="Path_847" data-name="Path 847" d="M133.144,123.3l12.094-6.983-12.094-6.983Z" transform="translate(-114.522 -100.026)" fill="#fff"/>
            </svg>
        </a>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    },
})
export default class Socials extends Vue {

}
</script>