<template>
    <div class="body-row" :class="{'body-row--centered': centered}">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        centered: Boolean
    }
})
export default class BodyRow extends Vue {

    centered!: boolean;

    mounted()
    {
        console.log(this.centered);
    }

}
</script>