
import { Options, Vue } from 'vue-class-component';
import Player from '@vimeo/player';

@Options({
    props: {
    }
})
export default class LeaderVideos extends Vue {

    public videos = [
        514219030,
        514243177,
        514244831,
    ];

    public thumbs = [
        { num: '0', image: 'introducing-ed', title: "Introducing Ed", color: "#6e7273" },
        { num: '1', image: 'getting-started', title: "Getting Started", color: "#22C3FF"  },
        { num: '2', image: 'needing-help', title: "Needing Help", color: "#EB3232"  },
    ]

    public index = 0;
    public player?: Player;

    mounted()
    {
        this.player = new Player('video-player', {
            id: this.videos[0],
            byline: false,
            portrait: false,
        });
        this.player.on('ended', this.onEnded);
    }

    // get curId(): string
    // {
    //     return this.videos[this.index];
    // }

    getThumbnail(name: string)
    {
        return require(`@/assets/${name}.jpg`)
    }

    private onEnded()
    {
        if(this.index < this.videos.length-1)
        {
            this.showVideo(this.index+1);
        }
    }

    public async showVideo(i: number)
    {
        this.index = Math.max(0, Math.min(this.videos.length-1, i));

        if(this.player)
        {
            await this.player.loadVideo(this.videos[this.index]);
            this.player.play();
        }
    }

}
