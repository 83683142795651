
import { Options, Vue } from 'vue-class-component';

import AlphabetPanel from './AlphabetPanel.vue';
import AudioPlayer from './AudioPlayer.vue';
import CTAButton from '@/components/common/CTAButton.vue';
import YamlService from '@/service/YamlService';
import AlphabetData from '@/alphabet.json';

interface AlphabetData {
    letter: string;
    title: string;
    shortTitle: string;
    subtitle: string;
    body: string;
}

@Options({
    props: {
    },
    components: {
        AlphabetPanel, AudioPlayer, CTAButton
    }
})
export default class AlphabetTool extends Vue {

    private playing = false;
    private curIndex = 0;
    // eslint-disable-next-line
    private alphabetData = this.getAlphabetData();
    private block = false;
    private autoPlay = false;
    private letterGroup = 0;

    private lefts: Array<number> = [23,24,25];
    private rights: Array<number> = [1,2,3];

    getAlphabetData(): Array<AlphabetData>
    {
        const data: Array<AlphabetData> = YamlService.stripTrans(AlphabetData)['letters'];

        return data;
    }

    private handleShare()
    {
        console.log('Share', this.alphabetData[this.curIndex]);
    }

    setCurrent(i: number)
    {
        if(this.block || i == this.curIndex) return;

        this.curIndex = i;
        if(i < 0) this.curIndex = 26 + i%26;
        if(i > 25) this.curIndex = i%26;

        // Update the lefts and rights arrays
        this.lefts = [this.curIndex-3, this.curIndex-2, this.curIndex-1].map((v) => v < 0 ? 26+v : v);
        this.rights = [this.curIndex+1, this.curIndex+2, this.curIndex+3].map((v) => v > 25 ? v-26 : v);

        // Stop the player
        this.playing = false;

        const player = (this.$refs.player as AudioPlayer);
        if(player) player.pause();

        this.block = true;
        setTimeout(() => this.block = false, 500);
    }

    setLetterGroup(i: number)
    {
        this.letterGroup = i;
        this.setCurrent(i*7);
    }

    classesForIndex(i: number)
    {
        const classes = [];

        // Update card position based on current maps
        if(this.lefts.includes(i))
        {
            const delta = 3 - this.lefts.indexOf(i);
            classes.push(`alphabet-tool__panel--neg${delta}`);
        }
        else if(this.rights.includes(i))
        {
            const delta = 1 + this.rights.indexOf(i);
            classes.push(`alphabet-tool__panel--pos${delta}`);
        }
        else if(i !== this.curIndex){
            classes.push(`alphabet-tool__panel--hidden`);
        }

        /**
         * Below was the mod to allow wrapping without left/right arrays.
         */

        // const diff = Math.min(4, Math.abs(this.curIndex - i));

        // if(i < this.curIndex)
        // {
        //     // classes.push(`alphabet-tool__panel--neg${diff}`);
        //     classes.push(`alphabet-tool__panel--prev`);

        //     if(this.curIndex <= 25 && this.curIndex >= 22 && i <= 3)
        //     {
        //         const wrapDiff = 1 + i;
        //         classes.push(`alphabet-tool__panel--pos${wrapDiff}`);
        //     } else {
        //         classes.push(`alphabet-tool__panel--neg${diff}`);
        //     }
        // }

        // if(i > this.curIndex)
        // {

        //     classes.push(`alphabet-tool__panel--next`);

        //     if(this.curIndex >= 0 && this.curIndex <= 3 && i >= 22)
        //     {
        //         const wrapDiff = Math.min(4, 26 + this.curIndex - i);

        //         classes.push(`alphabet-tool__panel--neg${wrapDiff}`);
        //     } else {
        //         classes.push(`alphabet-tool__panel--pos${diff}`);
        //     }
        // }


        if(i == this.curIndex)
        {
            classes.push(`alphabet-tool__panel--center`);
        }

        return classes;
    }

    col(n: number)
    {
        const i = n-1;
        return this.alphabetData.slice(i*7, i*7+7);
    }

    playpause()
    {
        this.playing = !this.playing;

        if(this.playing)
        {
            (this.$refs.player as AudioPlayer).play();
        } else {
            (this.$refs.player as AudioPlayer).pause();
        }
    }

    onPause()
    {
        this.playing = false;
    }

    onPlay()
    {
        this.playing = true;
    }

    onFinish()
    {
        if(this.autoPlay && this.curIndex < 25)
        {
            this.setCurrent(this.curIndex+1);
            // this.curIndex +=1;
            const newGroup = Math.floor((this.curIndex)/7);
            console.log(`Updating letter group: ${newGroup}`);
            this.letterGroup = newGroup;
        } else {
            this.autoPlay = false;
            this.curIndex
        }
    }

    toggleAutoplay()
    {
        this.autoPlay = !this.autoPlay;

        if(this.autoPlay)
        {
            (this.$refs.player as AudioPlayer).play();
        } else {
            (this.$refs.player as AudioPlayer).pause();
        }
    }

    get autoplayText()
    {
        if(!this.autoPlay) return this.$t('dbyloi_generic_autoplay_all_cta');

        return "Stop Autoplay";
    }

}
