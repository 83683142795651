
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
    },
})
export default class Socials extends Vue {

}
