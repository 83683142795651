<template>
    <div class="alphabet-tool__panel" :class="{'alphabet-tool__panel--active': active}">
        <a href="#alphabet-tool" @click.prevent="this.showShare(true);" class="alphabet-tool__share" :class="{'alphabet-tool__share--active': active}"><img v-svg-inline  src="@/assets/app-images/svgs/share.svg" /></a>
        <div class="alphabet-tool__panel-copy-wrapper" :class="{'alphabet-tool__panel-copy-wrapper--active': active}">
            <p class="alphabet-tool__panel-title">{{ $t(title) }}</p>
            <p class="alphabet-tool__panel-subtitle">{{ $t(subtitle) }}</p>
            <p class="alphabet-tool__panel-body">{{ $t(body) }}</p>
            <p class="alphabet-tool__panel-answer">{{ $t(answer) }}</p>
        </div>

        <CTAButton v-if="letter=='s'" @click="onSamaritans" icon="scrub-forward" class="alphabet-tool__panel-samaritans">Samaritans</CTAButton>

        <CTAButton icon="sound-white" @click="playpause" class="alphabet-tool__panel-playing" :class="{'alphabet-tool__panel-playing--active': active, 'alphabet-tool__panel-playing--samaritans': letter=='s'}" blue>{{ playText }}</CTAButton>

        <div v-if="controls" class="alphabet-tool__panel-audio-controls" :class="{'alphabet-tool__panel-audio-controls--active': active}">
            <span @click="prev" class="alphabet-tool__panel-audio-controls-back">
                <img v-svg-inline src="@/assets/app-images/svgs/scrub-back.svg"  />
            </span>
            <span @click="playpause" class="alphabet-tool__panel-audio-controls-playpause">
                <img v-svg-inline :src="playPauseSrc" />
            </span>
            <span @click="next" class="alphabet-tool__panel-audio-controls-forward">
                <img v-svg-inline  src="@/assets/app-images/svgs/scrub-forward.svg" />
            </span>
        </div>

        <transition name="fade">
            <div v-if="isSharing" class="alphabet-tool__panel-share">
                <a class="alphabet-tool__panel-share-close" @click.prevent="showShare(false)" href="#">
                    <img v-svg-inline src="@/assets/app-images/svgs/x.svg" />
                </a>
                <div class="alphabet-tool__panel-share-container">
                    <p class="alphabet-tool__panel-title">{{ $t(title) }}</p>
                    <div class="alphabet-tool__panel-share-socials">
                        <p>Share this card on Social Media:</p>
                        <Socials />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Socials from "@/components/Socials.vue";

import CTAButton from '@/components/common/CTAButton.vue';

@Options({
    props: {
        letter: String,
        title: String,
        subtitle: String,
        body: String,
        answer: String,
        active: Boolean,
        playing: Boolean,
        controls: Boolean,
    },
    components: {
        CTAButton, Socials
    },
    emits: [
        'prev', 'playpause', 'next', 'share'
    ]
})
export default class AlphabetPanel extends Vue {

    private letter?: string;
    private title?: string;
    private subtitle?: string;
    private body?: string;
    private answer?: string;
    private active?: boolean;
    private playing?: boolean;
    private controls?: boolean;

    private isSharing = false;

    private prev()
    {
        this.$emit('prev');
    }

    private playpause()
    {
        this.$emit('playpause');
    }
    private next()
    {
        this.$emit('next');
    }

    private onSamaritans()
    {
        window.open('https://www.samaritans.org', '_blank');
    }

    private showShare(state: boolean)
    {
        this.isSharing = state;
    }

    get playText(): string
    {
        if(this.playing) return this.$t('dbyloi_site_alphabettool_playing_button');

        return this.$t('dbyloi_generic_play_cta');
    }

    get playPauseSrc()
    {
        if(this.playing) return require("@/assets/app-images/svgs/pause.svg");

        return require("@/assets/app-images/svgs/play-sound.svg");
    }

}
</script>