<template>
    <div class="how-to-stop">
        <a id="stop-gambling" class="anchor" />
        <h2 class="how-to-stop__header"><span>{{ $t('dbyloi_site_howtostop_header') }}</span></h2>
        <div class="how-to-stop__copy body-block__centered">
            <CTAButton @click="onPlay" icon="sound" class="how-to-stop__listen">
                <span v-if="!playing">{{ $t('dbyloi_generic_audio_listen_cta') }}</span>
                <span v-if="playing">Now Playing...</span>
            </CTAButton>
            <p class="how-to-stop__body how-to-stop__body--blue how-to-stop__body--strong">{{ $t('dbyloi_site_howtostop_quote_1') }}</p>
            <p v-html="format('dbyloi_site_howtostop_body_1')"></p>
            <p v-html="format('dbyloi_site_howtostop_body_2')"></p>
            <p v-html="format('dbyloi_site_howtostop_body_3')"></p>
            <p class="how-to-stop__body--blue how-to-stop__body--strong" v-html="format('dbyloi_site_howtostop_quote_2')"></p>
            <p v-html="format('dbyloi_site_howtostop_body_4')"></p>
            <p v-html="format('dbyloi_site_howtostop_body_5')"></p>
            <p v-html="format('dbyloi_site_howtostop_body_6')"></p>
            <p v-html="format('dbyloi_site_howtostop_body_7')"></p>
            <p class="how-to-stop__body--blue how-to-stop__body--strong">{{ $t('dbyloi_site_howtostop_quote_3') }}</p>
        </div>
        <div class="how-to-stop__audio-wrapper">
            <iframe id="sc-player-how-to-stop" width="100%" height="100%" scrolling="no" frameborder="no" allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/946726024&amp;color=%2323C3FF&amp;show_user=false&amp;show_playcount=false&amp;show_artwork=false&amp;auto_play=false&amp;show_teaser=false"
            ></iframe>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import CTAButton from '@/components/common/CTAButton.vue';

// Exposed by SoundCloud API
// eslint-disable-next-line
declare const SC: any;

@Options({
    props: {
    },
    components: {
        CTAButton
    }
})
export default class HowtoStop extends Vue {

    // eslint-disable-next-line
    private widget: any;

    private playing = false;

    public mounted()
    {
        const widgetIframe = document.getElementById('sc-player-how-to-stop');
        const widget = SC.Widget(widgetIframe);

        this.widget = widget;

        widget.bind(SC.Widget.Events.PLAY , () => {
            this.playing = true;
        });

        widget.bind(SC.Widget.Events.PAUSE , () => {
            this.playing = false;
        });
    }

    private format(key: string)
    {
        const str = this.$t(key);
        const bold = /\*\*(.*?)\*\*/gm;
        return str.replace(bold, '<strong>$1</strong>');
    }

    public onPlay()
    {
        this.playing = !this.playing;

        if(this.playing)
        {
            this.widget.play();
        } else {
            this.widget.pause();
        }
    }

}
</script>